import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import Image from "@components/utility/Image";
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { KfHeroUtility } from "@klickinc/kf-react-components";
import { Link } from "gatsby";
import "./styles.scss";

const SiteMap = ({ data }) => {
	const ctx = useContext(AppContext);

	const image_sitemapPoly = getImage(data.sitemapPoly);
	useEffect(() => {
		ctx.setPageClass("page--sitemap relative min-h-screen");
	}, []);

	return (
		<>
			<div className="sitemap-poly">
				<Image
					imageData={image_sitemapPoly}
					loading="eager"
					objectFit="contain"
				/>
			</div>
			<section>
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading ">
						Site Map
					</KfHeroUtility>
					<div className="links-container">
						<ul className="text-xl font-bold normal-case heading lg:text-2xl lg:py-1">
							<li>
								<Link to="/">Homepage</Link>
								<span className="sitemapArrowList">
									<span className="arrow-right"></span>
								</span>
							</li>
							<li>
								<Link to="/understanding-corticosteroids">
									Understanding Corticosteroids
								</Link>
								<span className="sitemapArrowList">
									<span className="arrow-right"></span>
								</span>
							</li>
							<li>
								<Link to="/about-emflaza">About EMFLAZA</Link>
								<span className="sitemapArrowList">
									<span className="arrow-right"></span>
								</span>
							</li>
							<li>
								<Link to="/safety">Safety</Link>
								<span className="sitemapArrowList">
									<span className="arrow-right"></span>
								</span>
							</li>
							<li>
								<Link to="/dosing">Dosing</Link>
								<span className="sitemapArrowList">
									<span className="arrow-right"></span>
								</span>
							</li>
							<li>
								<span className="linklist-title">Getting Started</span>
								<ul>
									<li>
										<Link to="/ptc-cares-helps">
											PTC <span className='italic'>Cares</span><sup>&trade;</sup>
										</Link>
										<span className="sitemapArrowList">
											<span className="arrow-right"></span>
										</span>
									</li>
									<li>
										<Link to="/caregiver-resources">
											Caregiver Resources
										</Link>
										<span className="sitemapArrowList">
											<span className="arrow-right"></span>
										</span>
									</li>
									<li>
										<Link to="/doctor-discussion-guide">
											Doctor Discussion Guide
										</Link>
										<span className="sitemapArrowList">
											<span className="arrow-right"></span>
										</span>
									</li>
									<li>
										<Link to="/video-library">
											Video Library
										</Link>
										<span className="sitemapArrowList">
											<span className="arrow-right"></span>
										</span>
									</li>
								</ul>
							</li>
							<li>
								<Link to="/es">
									Información en Español
								</Link>
								<span className="sitemapArrowList">
									<span className="arrow-right"></span>
								</span>
							</li>
						</ul>
					</div>
				</div>
				<div className="sitemap-poly2 z-negative"></div>
			</section>
		</>
	);
};

export default SiteMap;


export const pageQuery = graphql`
  query {
	sitemapPoly: file(relativePath: {eq: "pages/sitemap/sitemap-poly.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo title="Sitemap - EMFLAZA® (deflazacort)" addSocialMeta={false} />
	);
};
